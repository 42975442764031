import './skin.scss';

const gridLoadType = 'dynamic'; // null

const commonColor = 'grey-7';
const commonDetailField = {
    filled: true,
    color: commonColor,
    textColor: 'grey-9',
};
const commonEditField = {
    filled: true,
    color: commonColor,
    hideBottomSpace: true,
};
const commonTable = {
    color: commonColor,
    loadType: gridLoadType || 'static',
    loadTypeEditable: true,
    selection: 'single',
    separator: 'cell',
    showRowCount: true,
    stickyHeader: true,
    stickyColumn: true,
    tableFilter: true,
    fullscreenToggle: true,
    selectOnRowClick: true,
    booleanAsCheckbox: true,
    columnsMinWidth: 50,
    columnsFilter: true,
    columnsResize: true,
    columnsResizeToFit: true,
    columnsReorder: true,
    columnsControl: true,
    reload: true,
    toolbarButtonsProps: { unelevated: true, color: commonColor, textColor: 'white' },
};

export default {
    e_color: commonColor,
    e_login_page: {},
    e_login_card: {},
    e_login_input: {
        filled: commonEditField.filled,
        color: commonEditField.color,
        labelColor: 'grey-6',
    },
    e_login_button: {},
    e_main_header: {},
    e_main_toolbar: {},
    e_main_toolbar_button: {},
    e_main_toolbar_popup: {},
    e_main_toolbar_popup_card: {},
    e_main_toolbar_popup_list: {},
    e_main_toolbar_popup_list_item: {},
    e_main_toolbar_popup_list_checkbox: {},
    e_main_drawer: {},
    e_main_drawer_profileList: {},
    e_main_drawer_profileList_item: {},
    e_main_drawer_menuList: {},
    e_main_drawer_menuList_item: {},
    e_main_drawer_menuList_expansionItem: {},
    e_main_drawer_footer: {},
    e_main_pageContainer: {},
    e_profile_page: {},
    e_profile_card: {},
    e_page_tableView: {},
    e_tabs: {},
    e_tab: {},
    e_tabPanels: {},
    e_tabPanel_tableView: {},
    e_card: {},
    e_card_toolbar: {},
    e_card_button_add: {},
    e_card_button_remove: {},
    e_gallery: {},
    e_table: {
        ...commonTable,
    },
    e_toolbar: {},
    e_toolbar_button: {},
    e_detailField: {
        ...commonDetailField,
    },
    e_input: {
        ...commonEditField,
    },
    e_input_textarea: {
        ...commonEditField,
        inputStyle: 'resize: none',
    },
    e_dateTimeInput: {
        ...commonEditField,
    },
    e_checkbox: {
        color: commonEditField.color,
    },
    e_select: {
        ...commonEditField,
    },
    e_chooseInput: {
        ...commonEditField,
        class: 'e-input e-choose',
        gridLoadType: gridLoadType || 'static',
        'grid-toolbar-props': {
            'menu-button-props': {
                class: 'e-table-overflowPopupButton',
            },
        },
    },

    e_fileInput_multi: {
        ...commonEditField,
    },
};
