import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router';
import LoginLayout from '../layouts/LoginLayout.vue';
import MainLayout from '../layouts/MainLayout.vue';

const routes = [
    {
        name: 'root',
        path: '/:pathMatch(.*)*',
        beforeEnter(to, from) {
            return false;
        },
    },
];
const loginRoutes = [{ name: 'login', path: '/:pathMatch(.*)*', component: LoginLayout }];
const mainRoutes = [
    {
        name: 'root',
        path: '/',
        component: MainLayout,
        // prettier-ignore
        children: [
            { name: 'main', path: '/', component: () => import(/* webpackChunkName: "main" */ '../views/MainView.vue') },
            { name: 'profile', path: '/profile', component: () => import(/* webpackChunkName: "profile" */ '../views/ProfileView.vue') },
            { name: 'address_country', path: '/address_country', component: () => import(/* webpackChunkName: "address_country" */ '../views/AddressCountry/AddressCountryView.vue') },
            { name: 'address_country-detail', path: '/address_country/detail/:id', component: () => import(/* webpackChunkName: "address_country-detail" */ '../views/AddressCountry/AddressCountryDetailView.vue') },
            { name: 'address_country-edit', path: '/address_country/edit/:id?', component: () => import(/* webpackChunkName: "address_country-edit" */ '../views/AddressCountry/AddressCountryEditView.vue') },
            { name: 'address_town', path: '/address_town', component: () => import(/* webpackChunkName: "address_town" */ '../views/AddressTown/AddressTownView.vue') },
            { name: 'address_town-detail', path: '/address_town/detail/:id', component: () => import(/* webpackChunkName: "address_town-detail" */ '../views/AddressTown/AddressTownDetailView.vue') },
            { name: 'address_town-edit', path: '/address_town/edit/:id?', component: () => import(/* webpackChunkName: "address_town-edit" */ '../views/AddressTown/AddressTownEditView.vue') },
            { name: 'advice', path: '/advice', component: () => import(/* webpackChunkName: "advice" */ '../views/Advice/AdviceView.vue') },
            { name: 'advice-detail', path: '/advice/detail/:id', component: () => import(/* webpackChunkName: "advice-detail" */ '../views/Advice/AdviceDetailView.vue') },
            { name: 'advice-edit', path: '/advice/edit/:id?', component: () => import(/* webpackChunkName: "advice-edit" */ '../views/Advice/AdviceEditView.vue') },
            { name: 'article', path: '/article', component: () => import(/* webpackChunkName: "article" */ '../views/Article/ArticleView.vue') },
            { name: 'article-detail', path: '/article/detail/:id', component: () => import(/* webpackChunkName: "article-detail" */ '../views/Article/ArticleDetailView.vue') },
            { name: 'article-edit', path: '/article/edit/:id?', component: () => import(/* webpackChunkName: "article-edit" */ '../views/Article/ArticleEditView.vue') },
            { name: 'article-image-detail', path: '/article/image/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-image-detail" */ '../views/Article/Image/ImageDetailView.vue') },
            { name: 'article-image-edit', path: '/article/image/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-image-edit" */ '../views/Article/Image/ImageEditView.vue') },
            { name: 'article-video-detail', path: '/article/video/detail/:articleId/:id', component: () => import(/* webpackChunkName: "article-video-detail" */ '../views/Article/Video/VideoDetailView.vue') },
            { name: 'article-video-edit', path: '/article/video/edit/:articleId/:id?', component: () => import(/* webpackChunkName: "article-video-edit" */ '../views/Article/Video/VideoEditView.vue') },
            { name: 'change', path: '/change', component: () => import(/* webpackChunkName: "change" */ '../views/Change/ChangeView.vue') },
            { name: 'change-detail', path: '/change/detail/:id', component: () => import(/* webpackChunkName: "change-detail" */ '../views/Change/ChangeDetailView.vue') },
            { name: 'cpv_code', path: '/cpv_code', component: () => import(/* webpackChunkName: "cpv_code" */ '../views/CpvCode/CpvCodeView.vue') },
            { name: 'cpv_code-detail', path: '/cpv_code/detail/:id', component: () => import(/* webpackChunkName: "cpv_code-detail" */ '../views/CpvCode/CpvCodeDetailView.vue') },
            { name: 'cpv_code-edit', path: '/cpv_code/edit/:id?', component: () => import(/* webpackChunkName: "cpv_code-edit" */ '../views/CpvCode/CpvCodeEditView.vue') },
            { name: 'gallery', path: '/gallery', component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery/GalleryView.vue') },
            { name: 'gallery-detail', path: '/gallery/detail/:id', component: () => import(/* webpackChunkName: "gallery-detail" */ '../views/Gallery/GalleryDetailView.vue') },
            { name: 'gallery-edit', path: '/gallery/edit/:id?', component: () => import(/* webpackChunkName: "gallery-edit" */ '../views/Gallery/GalleryEditView.vue') },
            { name: 'gallery-gallery_entry-detail', path: '/gallery/gallery_entry/detail/:galleryId/:id', component: () => import(/* webpackChunkName: "gallery-gallery_entry-detail" */ '../views/Gallery/GalleryEntry/GalleryEntryDetailView.vue') },
            { name: 'gallery-gallery_entry-edit', path: '/gallery/gallery_entry/edit/:galleryId/:id?', component: () => import(/* webpackChunkName: "gallery-gallery_entry-edit" */ '../views/Gallery/GalleryEntry/GalleryEntryEditView.vue') },
            { name: 'helper', path: '/helper', component: () => import(/* webpackChunkName: "helper" */ '../views/Helper/HelperView.vue') },
            { name: 'helper-detail', path: '/helper/detail/:id', component: () => import(/* webpackChunkName: "helper-detail" */ '../views/Helper/HelperDetailView.vue') },
            { name: 'helper-edit', path: '/helper/edit/:id?', component: () => import(/* webpackChunkName: "helper-edit" */ '../views/Helper/HelperEditView.vue') },
            { name: 'log', path: '/log', component: () => import(/* webpackChunkName: "log" */ '../views/Log/LogView.vue') },
            { name: 'log-detail', path: '/log/detail/:id', component: () => import(/* webpackChunkName: "log-detail" */ '../views/Log/LogDetailView.vue') },
            { name: 'mail', path: '/mail', component: () => import(/* webpackChunkName: "mail" */ '../views/Mail/MailView.vue') },
            { name: 'mail-detail', path: '/mail/detail/:id', component: () => import(/* webpackChunkName: "mail-detail" */ '../views/Mail/MailDetailView.vue') },
            { name: 'mail_pattern', path: '/mail_pattern', component: () => import(/* webpackChunkName: "mail_pattern" */ '../views/MailPattern/MailPatternView.vue') },
            { name: 'mail_pattern-detail', path: '/mail_pattern/detail/:id', component: () => import(/* webpackChunkName: "mail_pattern-detail" */ '../views/MailPattern/MailPatternDetailView.vue') },
            { name: 'mail_pattern-edit', path: '/mail_pattern/edit/:id?', component: () => import(/* webpackChunkName: "mail_pattern-edit" */ '../views/MailPattern/MailPatternEditView.vue') },
            { name: 'notice', path: '/notice', component: () => import(/* webpackChunkName: "notice" */ '../views/Notice/NoticeView.vue') },
            { name: 'notice-detail', path: '/notice/detail/:id', component: () => import(/* webpackChunkName: "notice-detail" */ '../views/Notice/NoticeDetailView.vue') },
            { name: 'notice-edit', path: '/notice/edit/:id?', component: () => import(/* webpackChunkName: "notice-edit" */ '../views/Notice/NoticeEditView.vue') },
            { name: 'order', path: '/order', component: () => import(/* webpackChunkName: "order" */ '../views/Order/OrderView.vue') },
            { name: 'order-detail', path: '/order/detail/:id', component: () => import(/* webpackChunkName: "order-detail" */ '../views/Order/OrderDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-edit', path: '/order/edit/:id?', component: () => import(/* webpackChunkName: "order-edit" */ '../views/Order/OrderEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_part-detail', path: '/order/order_part/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_part-detail" */ '../views/Order/OrderPart/OrderPartDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_part-edit', path: '/order/order_part/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_part-edit" */ '../views/Order/OrderPart/OrderPartEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_partner_offer-detail', path: '/order/order_partner_offer/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_partner_offer-detail" */ '../views/Order/OrderPartnerOffer/OrderPartnerOfferDetailView.vue'), meta: { persistent: ['organization', 'orderPart'] } },
            { name: 'order-order_partner_offer-edit', path: '/order/order_partner_offer/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_partner_offer-edit" */ '../views/Order/OrderPartnerOffer/OrderPartnerOfferEditView.vue'), meta: { persistent: ['organization', 'orderPart'] } },
            { name: 'order-order_partner-detail', path: '/order/order_partner/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_partner-detail" */ '../views/Order/OrderPartner/OrderPartnerDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_partner-edit', path: '/order/order_partner/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_partner-edit" */ '../views/Order/OrderPartner/OrderPartnerEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_commission_member-detail', path: '/order/order_commission_member/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_commission_member-detail" */ '../views/Order/OrderCommissionMember/OrderCommissionMemberDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_commission_member-edit', path: '/order/order_commission_member/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_commission_member-edit" */ '../views/Order/OrderCommissionMember/OrderCommissionMemberEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_document-detail', path: '/order/order_document/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_document-detail" */ '../views/Order/OrderDocument/OrderDocumentDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_document-edit', path: '/order/order_document/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_document-edit" */ '../views/Order/OrderDocument/OrderDocumentEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_message-detail', path: '/order/order_message/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_message-detail" */ '../views/Order/OrderMessage/OrderMessageDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_message-edit', path: '/order/order_message/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_message-edit" */ '../views/Order/OrderMessage/OrderMessageEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_question-detail', path: '/order/order_question/detail/:orderId/:id', component: () => import(/* webpackChunkName: "order-order_question-detail" */ '../views/Order/OrderQuestion/OrderQuestionDetailView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order-order_question-edit', path: '/order/order_question/edit/:orderId/:id?', component: () => import(/* webpackChunkName: "order-order_question-edit" */ '../views/Order/OrderQuestion/OrderQuestionEditView.vue'), meta: { persistent: ['organization'] } },
            { name: 'order_document_type', path: '/order_document_type', component: () => import(/* webpackChunkName: "order_document_type" */ '../views/OrderDocumentType/OrderDocumentTypeView.vue') },
            { name: 'order_document_type-detail', path: '/order_document_type/detail/:id', component: () => import(/* webpackChunkName: "order_document_type-detail" */ '../views/OrderDocumentType/OrderDocumentTypeDetailView.vue') },
            { name: 'order_document_type-edit', path: '/order_document_type/edit/:id?', component: () => import(/* webpackChunkName: "order_document_type-edit" */ '../views/OrderDocumentType/OrderDocumentTypeEditView.vue') },
            { name: 'organization', path: '/organization', component: () => import(/* webpackChunkName: "organization" */ '../views/Organization/OrganizationView.vue') },
            { name: 'organization-detail', path: '/organization/detail/:id', component: () => import(/* webpackChunkName: "organization-detail" */ '../views/Organization/OrganizationDetailView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-edit', path: '/organization/edit/:id?', component: () => import(/* webpackChunkName: "organization-edit" */ '../views/Organization/OrganizationEditView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_user-detail', path: '/organization/organization_user/detail/:organizationId/:id', component: () => import(/* webpackChunkName: "organization-organization_user-detail" */ '../views/Organization/OrganizationUser/OrganizationUserDetailView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_user-edit', path: '/organization/organization_user/edit/:organizationId/:id?', component: () => import(/* webpackChunkName: "organization-organization_user-edit" */ '../views/Organization/OrganizationUser/OrganizationUserEditView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_partner-detail', path: '/organization/organization_partner/detail/:organizationId/:id', component: () => import(/* webpackChunkName: "organization-organization_partner-detail" */ '../views/Organization/OrganizationPartner/OrganizationPartnerDetailView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_partner-edit', path: '/organization/organization_partner/edit/:organizationId/:id?', component: () => import(/* webpackChunkName: "organization-organization_partner-edit" */ '../views/Organization/OrganizationPartner/OrganizationPartnerEditView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_payment-detail', path: '/organization/organization_payment/detail/:organizationId/:id', component: () => import(/* webpackChunkName: "organization-organization_payment-detail" */ '../views/Organization/OrganizationPayment/OrganizationPaymentDetailView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization-organization_payment-edit', path: '/organization/organization_payment/edit/:organizationId/:id?', component: () => import(/* webpackChunkName: "organization-organization_payment-edit" */ '../views/Organization/OrganizationPayment/OrganizationPaymentEditView.vue'), meta: { persistent: ['order', 'user', 'order_organization_user_announcer', 'order_organization_user_applicant'] } },
            { name: 'organization_legal_form', path: '/organization_legal_form', component: () => import(/* webpackChunkName: "organization_legal_form" */ '../views/OrganizationLegalForm/OrganizationLegalFormView.vue'), meta: { persistent: ['order'] } },
            { name: 'organization_legal_form-detail', path: '/organization_legal_form/detail/:id', component: () => import(/* webpackChunkName: "organization_legal_form-detail" */ '../views/OrganizationLegalForm/OrganizationLegalFormDetailView.vue'), meta: { persistent: ['order'] } },
            { name: 'organization_legal_form-edit', path: '/organization_legal_form/edit/:id?', component: () => import(/* webpackChunkName: "organization_legal_form-edit" */ '../views/OrganizationLegalForm/OrganizationLegalFormEditView.vue'), meta: { persistent: ['order'] } },
            { name: 'organization_size', path: '/organization_size', component: () => import(/* webpackChunkName: "organization_size" */ '../views/OrganizationSize/OrganizationSizeView.vue'), meta: { persistent: ['order'] } },
            { name: 'organization_size-detail', path: '/organization_size/detail/:id', component: () => import(/* webpackChunkName: "organization_size-detail" */ '../views/OrganizationSize/OrganizationSizeDetailView.vue'), meta: { persistent: ['order'] } },
            { name: 'organization_size-edit', path: '/organization_size/edit/:id?', component: () => import(/* webpackChunkName: "organization_size-edit" */ '../views/OrganizationSize/OrganizationSizeEditView.vue'), meta: { persistent: ['order'] } },
            { name: 'print_pattern', path: '/print_pattern', component: () => import(/* webpackChunkName: "print_pattern" */ '../views/PrintPattern/PrintPatternView.vue') },
            { name: 'print_pattern-detail', path: '/print_pattern/detail/:id', component: () => import(/* webpackChunkName: "print_pattern-detail" */ '../views/PrintPattern/PrintPatternDetailView.vue') },
            { name: 'print_pattern-edit', path: '/print_pattern/edit/:id?', component: () => import(/* webpackChunkName: "print_pattern-edit" */ '../views/PrintPattern/PrintPatternEditView.vue') },
            { name: 'reference', path: '/reference', component: () => import(/* webpackChunkName: "reference" */ '../views/Reference/ReferenceView.vue') },
            { name: 'reference-detail', path: '/reference/detail/:id', component: () => import(/* webpackChunkName: "reference-detail" */ '../views/Reference/ReferenceDetailView.vue') },
            { name: 'reference-edit', path: '/reference/edit/:id?', component: () => import(/* webpackChunkName: "reference-edit" */ '../views/Reference/ReferenceEditView.vue') },
            { name: 'setting', path: '/setting', redirect: '/setting/detail' },
            { name: 'setting-detail', path: '/setting/detail', component: () => import(/* webpackChunkName: "setting-detail" */ '../views/Setting/SettingDetailView.vue') },
            { name: 'setting-edit', path: '/setting/edit', component: () => import(/* webpackChunkName: "setting-edit" */ '../views/Setting/SettingEditView.vue') },
            { name: 'subpage', path: '/subpage', component: () => import(/* webpackChunkName: "subpage" */ '../views/Subpage/SubpageView.vue') },
            { name: 'subpage-detail', path: '/subpage/detail/:id', component: () => import(/* webpackChunkName: "subpage-detail" */ '../views/Subpage/SubpageDetailView.vue') },
            { name: 'subpage-edit', path: '/subpage/edit/:id?', component: () => import(/* webpackChunkName: "subpage-edit" */ '../views/Subpage/SubpageEditView.vue') },
            { name: 'subpage-image-detail', path: '/subpage/image/detail/:subpageId/:id', component: () => import(/* webpackChunkName: "subpage-image-detail" */ '../views/Subpage/Image/ImageDetailView.vue') },
            { name: 'subpage-image-edit', path: '/subpage/image/edit/:subpageId/:id?', component: () => import(/* webpackChunkName: "subpage-image-edit" */ '../views/Subpage/Image/ImageEditView.vue') },
            { name: 'user', path: '/user', component: () => import(/* webpackChunkName: "user" */ '../views/User/UserView.vue') },
            { name: 'user-detail', path: '/user/detail/:id', component: () => import(/* webpackChunkName: "user-detail" */ '../views/User/UserDetailView.vue'), meta: { persistent: ['organization', 'organization_user'] } },
            { name: 'user-edit', path: '/user/edit/:id?', component: () => import(/* webpackChunkName: "user-edit" */ '../views/User/UserEditView.vue'), meta: { persistent: ['organization', 'organization_user'] } },
        ],
    },
    { name: 'catch', path: '/:catchAll(.*)*', redirect: '/' },
];

const router = createRouter({
    history: process.env.VUE_APP_ROUTER_MODE == 'hash' ? createWebHashHistory(process.env.BASE_URL) : createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    // console.log('beforeEach', from, to);
    // return false;
    if (to.meta && to.meta.persistent && !!Object.keys(from.query).length) {
        let success = false;
        for (let i = 0; i < to.meta.persistent.length; i++) {
            const persistent = to.meta.persistent[i];
            if (from.query[persistent] && !to.query[persistent]) {
                to.query[persistent] = from.query[persistent];
                success = true;
            }
        }
        if (success) {
            next(to);
        } else {
            next();
        }
    } else {
        next();
    }
});
router.beforeResolve((to, from) => {
    // console.log('beforeResolve', from, to);
    // return false;
});
router.afterEach((to, from) => {
    // console.log('afterEach', from, to);
    // return false;
});

router.addRoutes = function (routes) {
    router.options.routes = routes;
    routes.forEach((route) => {
        router.addRoute(route);
    });
};
router.removeRoutes = function () {
    router.options.routes.forEach((route) => {
        router.removeRoute(route.name);
    });
    router.options.routes = [];
};
router.reload = function () {
    const path = window.location.href.replace(window.location.origin + router.options.history.base, '');
    router.replace(router.resolve(path));
};
router.login = function () {
    router.removeRoutes();
    router.addRoutes(mainRoutes);
    router.reload();
};
router.logout = function () {
    router.removeRoutes();
    router.addRoutes(loginRoutes);
    router.reload();
};

export default router;
