<template>
    <q-layout view="lHh Lpr lFf">
        <q-header v-bind="$e.config.props.e_main_header" class="bg-grey-3 text-grey-7">
            <q-toolbar v-bind="$e.config.props.e_main_toolbar" class="q-pa-sm">
                <q-btn :ripple="false" icon="menu" unelevated round @click="toggleMenu" />
                <q-btn
                    v-if="appBack"
                    :to="typeof appBack === 'function' ? null : appBack"
                    icon="arrow_back"
                    unelevated
                    round
                    class="q-ml-xs"
                    @click="typeof appBack === 'function' && appBack()"
                />
                <q-toolbar-title>{{ appTitle }}</q-toolbar-title>
                <q-btn
                    v-bind="$e.config.props.e_main_toolbar_button"
                    icon="more_vert"
                    unelevated
                    round
                    color="grey-7"
                    text-color="white"
                    size="md"
                    class="q-ml-sm"
                >
                    <q-popup-proxy v-bind="$e.config.props.e_main_toolbar_popup" ref="optionsMenu" :offset="[0, 5]">
                        <q-card v-bind="$e.config.props.e_main_toolbar_popup_card">
                            <q-bar v-if="$refs.optionsMenu.currentComponent.type == 'dialog'" class="q-px-xs">
                                <q-space />
                                <q-btn dense round flat icon="close" color="grey-7" v-close-popup />
                            </q-bar>
                            <q-list v-bind="$e.config.props.e_main_toolbar_popup_list">
                                <q-item v-bind="$e.config.props.e_main_toolbar_popup_list_item" clickable v-close-popup @click="$q.fullscreen.toggle()">
                                    <q-item-section avatar>
                                        <q-icon :name="$q.fullscreen.isActive ? 'fullscreen_exit' : 'fullscreen'" size="md" color="grey-7" class="q-ma-xs" />
                                    </q-item-section>
                                    <q-item-section class="ellipsis">
                                        {{ $q.fullscreen.isActive ? 'Zrušiť celú obrazovku' : 'Na celú obrazovku' }}
                                    </q-item-section>
                                </q-item>
                                <q-separator />
                                <q-item v-bind="$e.config.props.e_main_toolbar_popup_list_item" clickable @click="toggleStorage">
                                    <q-item-section avatar>
                                        <q-checkbox v-bind="$e.config.props.e_main_toolbar_popup_list_checkbox" v-model="storage" color="grey-7" />
                                    </q-item-section>
                                    <q-item-section>Ukladať nastavenia</q-item-section>
                                </q-item>
                                <q-item v-bind="$e.config.props.e_main_toolbar_popup_list_item" clickable v-close-popup @click="clearStorage">
                                    <q-item-section avatar>
                                        <q-icon name="o_delete" size="md" color="grey-7" class="q-ma-xs" />
                                    </q-item-section>
                                    <q-item-section>Vymazať nastavenia</q-item-section>
                                </q-item>
                                <q-separator />
                                <q-item v-bind="$e.config.props.e_main_toolbar_popup_list_item" v-if="appInfo" clickable v-close-popup @click="showInfo">
                                    <q-item-section avatar>
                                        <q-icon name="o_info" size="md" color="grey-7" class="q-ma-xs" />
                                    </q-item-section>
                                    <q-item-section>O aplikácii</q-item-section>
                                </q-item>
                                <q-item v-bind="$e.config.props.e_main_toolbar_popup_list_item" clickable v-close-popup class="lt-sm" @click="logout">
                                    <q-item-section avatar>
                                        <q-icon name="power_settings_new" size="md" color="grey-7" class="q-ma-xs" />
                                    </q-item-section>
                                    <q-item-section>Odhlásiť</q-item-section>
                                </q-item>
                            </q-list>
                        </q-card>
                    </q-popup-proxy>
                </q-btn>
                <q-btn
                    v-bind="$e.config.props.e_main_toolbar_button"
                    icon="power_settings_new"
                    unelevated
                    round
                    color="grey-7"
                    text-color="white"
                    size="md"
                    class="gt-xs q-ml-sm"
                    @click="logout"
                />
            </q-toolbar>
        </q-header>
        <q-drawer
            bordered
            v-bind="$e.config.props.e_main_drawer"
            v-model="menuOpened"
            show-if-above
            class="flex column no-wrap bg-grey-3 full-width"
            :breakpoint="1040"
        >
            <div class="lt-md absolute" style="top: 5px; right: 5px; z-index: 1">
                <q-btn dense round unelevated icon="close" text-color="grey-7" @click="toggleMenu" />
            </div>
            <div class="col-shrink q-pb-xs">
                <div class="e-main-layout__main-logo q-mt-lg q-px-xl">
                    <q-img
                        :src="publicPath + 'assets/images/enforb.png'"
                        loading="lazy"
                        spinner-color="grey-7"
                        fit="contain"
                        class="cursor-pointer"
                        style="height: 50px"
                        @click="$router.push('/')"
                    />
                </div>
                <q-list v-bind="$e.config.props.e_main_drawer_profileList" class="e-main-layout__profile-menu q-pb-xs q-mt-lg">
                    <q-item
                        v-bind="$e.config.props.e_main_drawer_profileList_item"
                        clickable
                        to="/profile"
                        class="q-pl-lg"
                        active-class="e-main-layout__menu__active text-bold bg-grey-4"
                    >
                        <q-item-section avatar class="col-shrink items-center bg-grey-7 text-white rounded-borders q-pa-xs q-mr-md">
                            <q-icon name="person" size="md" />
                        </q-item-section>
                        <q-item-section class="q-ma-none">
                            <q-item-label>{{ user.fullName }}</q-item-label>
                            <q-item-label v-if="user.mail" caption>{{ user.mail }}</q-item-label>
                        </q-item-section>
                    </q-item>
                </q-list>
                <q-separator inset />
            </div>
            <div class="col-grow">
                <q-scroll-area class="full-height">
                    <q-list v-bind="$e.config.props.e_main_drawer_menuList" ref="mainMenuList" class="e-main-layout__main-menu">
                        <template v-for="menuItem in mainMenu" :key="menuItem.path">
                            <q-item
                                v-if="!menuItem.children"
                                v-bind="$e.config.props.e_main_drawer_menuList_item"
                                :to="menuItem.path"
                                :active="isPathMatched(menuItem.path)"
                                clickable
                                active-class="e-main-layout__menu__active text-bold bg-grey-4"
                                class="q-pl-lg"
                                :ref="
                                    (vm) => {
                                        if (vm) {
                                            menuRefs[menuItem.path] = vm;
                                        }
                                    }
                                "
                            >
                                <q-item-section v-if="menu.filter((item) => item.icon).length > 0" class="col-shrink items-center q-mr-sm">
                                    <q-icon :name="menuItem.icon" size="md" class="text-grey-7" />
                                </q-item-section>
                                <q-item-section class="q-ma-none">
                                    <q-item-label>{{ menuItem.label }}</q-item-label>
                                    <q-item-label v-if="menuItem.caption" caption>{{ menuItem.caption }}</q-item-label>
                                </q-item-section>
                            </q-item>
                            <q-expansion-item
                                v-else
                                v-bind="$e.config.props.e_main_drawer_menuList_expansionItem"
                                :icon="menuItem.icon"
                                :label="menuItem.label"
                                :caption="menuItem.caption"
                                :default-opened="menuItem.children.findIndex((item) => isPathMatched(item.path)) >= 0"
                                header-class="q-pl-lg"
                            >
                                <template v-slot:header="">
                                    <q-item-section v-if="menu.filter((item) => item.icon).length > 0" class="col-shrink items-center q-mr-sm">
                                        <q-icon :name="menuItem.icon" size="md" class="text-grey-7" />
                                    </q-item-section>
                                    <q-item-section class="q-ma-none">
                                        <q-item-label>{{ menuItem.label }}</q-item-label>
                                        <q-item-label v-if="menuItem.caption" caption>{{ menuItem.caption }}</q-item-label>
                                    </q-item-section>
                                </template>
                                <template v-slot:default="">
                                    <q-separator v-if="menuItem.separator === 'menu'" class="q-mx-md" />
                                    <template v-for="childMenuItem in menuItem.children" :key="childMenuItem.path">
                                        <q-item
                                            v-if="!childMenuItem.children"
                                            v-bind="$e.config.props.e_main_drawer_menuList_item"
                                            :to="childMenuItem.path"
                                            :active="isPathMatched(childMenuItem.path)"
                                            clickable
                                            :inset-level="0.5"
                                            active-class="e-main-layout__menu__active text-bold bg-grey-4"
                                            class="q-pl-lg"
                                            :ref="
                                                (vm) => {
                                                    if (vm) {
                                                        menuRefs[childMenuItem.path] = vm;
                                                    }
                                                }
                                            "
                                        >
                                            <q-item-section
                                                v-if="menuItem.children.filter((item) => item.icon).length > 0"
                                                class="col-shrink items-center q-mr-sm"
                                            >
                                                <q-icon :name="childMenuItem.icon" size="md" class="text-grey-7" />
                                            </q-item-section>
                                            <q-item-section class="q-ma-none">
                                                <q-item-label>{{ childMenuItem.label }}</q-item-label>
                                                <q-item-label v-if="childMenuItem.caption" caption>{{ childMenuItem.caption }}</q-item-label>
                                            </q-item-section>
                                        </q-item>
                                        <q-separator v-if="childMenuItem.separator" class="q-mx-md" />
                                    </template>
                                    <q-separator v-if="menuItem.separator === 'menu'" class="q-mx-md" />
                                </template>
                            </q-expansion-item>
                            <q-separator v-if="menuItem.separator === true" class="q-mx-md" />
                        </template>
                    </q-list>
                </q-scroll-area>
            </div>
            <div class="e-main-layout__main-copyright col-shrink q-py-xs">
                <q-separator inset />
                <div v-bind="$e.config.props.e_main_drawer_footer" class="flex row items-center no-wrap q-px-md q-pt-xs">
                    <span class="col-grow col-shrink text-bold ellipsis text-grey-7" style="flex: 1 1 auto">Copyright © 2023 Enforb s.r.o.</span>
                    <q-btn
                        unelevated
                        round
                        text-color="grey-7"
                        icon="o_info"
                        size="md"
                        class="q-pa-none"
                        @click="
                            infoDialog = true;
                            $q.screen.lt.md && toggleMenu();
                        "
                    />
                </div>
            </div>
        </q-drawer>
        <q-page-container v-bind="$e.config.props.e_main_pageContainer">
            <router-view />
            <!-- <router-view v-slot="{ Component }">
                <transition
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__fadeOut"
                    mode="out-in"
                    :duration="500"
                >
                    <component :is="Component" />
                </transition>
            </router-view> -->
            <!-- <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view> -->
            <!-- <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component" v-if="$route.meta.keepAlive" />
                </keep-alive>
                <component v-if="!$route.meta.keepAlive" :is="Component" />
            </router-view> -->
        </q-page-container>
    </q-layout>
    <q-dialog v-model="infoDialog" persistent>
        <q-card style="width: 480px; max-width: 80vw">
            <q-card-section>
                <div class="text-h6">EnFIS 4.0</div>
            </q-card-section>
            <q-separator />
            <q-card-section class="q-py-md row flex-center">
                <div class="col-12 col-sm-5">
                    <div>Verzia: 4.0.00</div>
                    <div>Dátum vydania: 01.01.2023</div>
                    <div>EnForb © 2023</div>
                    <div>Všetky práva vyhradené</div>
                </div>
                <div class="col-12 col-sm-7 text-center q-mt-md q-mt-sm-none">
                    <q-img
                        :src="publicPath + 'assets/images/enforb.png'"
                        loading="lazy"
                        spinner-color="grey-7"
                        fit="contain"
                        style="width: 250px; max-width: 100%"
                        class=""
                    />
                </div>
            </q-card-section>
            <q-separator />
            <q-card-actions align="right">
                <q-btn flat label="OK" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { extend } from 'quasar';
import { mapState } from 'vuex';
import menu from '../assets/menu.json';

export default {
    name: 'MainLayout',
    created() {
        this.$e.user.onSetUser((user) => {
            // this.user = user;
            this.user = extend(true, {}, user);
        });
    },
    beforeMount() {
        this.storage = this.$q.localStorage.getItem(this.$e.storageKey + 'settings');
        this.storage = !!this.storage;
        this.$store.commit('setAppSettingsStore', this.storage);
    },
    data() {
        return {
            publicPath: process.env.BASE_URL,
            menu: menu,
            menuOpened: false,
            menuRefs: [],
            infoDialog: false,
            storage: false,
            user: extend(true, {}, this.$e.user.getUser()),
        };
    },
    computed: {
        mainMenu() {
            if (this.$e.is.empty(this.user)) {
                return [];
            }
            const menu = extend(true, [], this.menu);
            return menu.filter((item) => {
                if (item.children) {
                    item.children = item.children.filter((item) => !item.permissions || this.$e.user.evalRoles(item.permissions));
                    return item.children.length > 0;
                }
                return !item.permissions || this.$e.user.evalRoles(item.permissions);
            });
        },
        ...mapState(['appInfo', 'appTitle', 'appBack', 'appSettingsStore']),
    },
    methods: {
        logout() {
            this.$e.user.logout(() => {
                this.$router.logout();
            });
        },
        toggleMenu() {
            this.menuOpened = !this.menuOpened;
        },
        toggleStorage(storage) {
            this.storage = typeof storage === 'boolean' ? storage : !this.storage;
            this.$store.commit('setAppSettingsStore', this.storage);
            this.$q.localStorage.set(this.$e.storageKey + 'settings', this.storage);
            // this.$e.loading.show();
            // document.location.reload();
        },
        clearStorage() {
            this.$e.dialog.question('Naozaj chcete vymazať nastavenia?', (dialog, result) => {
                if (result) {
                    this.toggleStorage(false);
                    this.$q.localStorage.clear();
                    this.$e.dialog.success('Nastavenia boli úspešne vymazané.', () => {
                        // this.$e.loading.show();
                        // document.location.reload();
                    });
                }
            });
        },
        showInfo() {
            this.$e.dialog.info(this.appInfo);
        },
        isPathMatched(path) {
            return this.$route.path === path || this.$route.path.startsWith(path + '/');
        },
        getMenuRef(path) {
            const index = path.indexOf('/', 1);
            if (index != -1) {
                path = path.substring(0, index);
            }
            return this.menuRefs[path];
        },
        getParentMenuItem(proxy) {
            if (Object(proxy.$parent) === proxy.$parent) {
                return proxy.$parent;
            }
            let { parent } = proxy.$;
            while (Object(parent) === parent) {
                if (Object(parent.proxy) === parent.proxy) {
                    return parent.proxy;
                }
                parent = parent.parent;
            }
        },
        updateMenu(menuItem) {
            if (menuItem !== void 0 && menuItem !== this.$refs.mainMenuList) {
                menuItem.show !== void 0 && menuItem.show();
                menuItem = this.getParentMenuItem(menuItem);
                if (menuItem !== void 0) {
                    this.updateMenu(menuItem);
                }
            }
        },
    },
    watch: {
        '$route.path': {
            handler(path) {
                this.updateMenu(this.getMenuRef(path));
            },
            immediate: false,
            deep: false,
        },
    },
};
</script>

<style lang="scss" scoped>
:deep() .q-drawer {
    width: 100% !important;
    max-width: 300px !important;
}
:deep() .e-main-layout__main-menu .q-item {
    height: 55px;
}
:deep() .e-main-layout__main-menu .q-item::before,
:deep() .e-main-layout__profile-menu .q-item::before {
    content: ' ';
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 10px;
    width: 4px;
    background: $grey-7;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease;
}
:deep() .e-main-layout__main-menu .q-item.e-main-layout__menu__active::before,
:deep() .e-main-layout__profile-menu .q-item.e-main-layout__menu__active::before {
    opacity: 1;
}
:deep() .e-main-layout__main-menu .q-item.e-main-layout__menu__active,
:deep() .e-main-layout__profile-menu .q-item.e-main-layout__menu__active {
    color: $grey-9;
}
@media (max-height: 300px) {
    :deep() .e-main-layout__main-logo {
        display: none;
    }
    :deep() .e-main-layout__main-copyright {
        display: none;
    }
}
</style>
