<template>
    <router-view />
</template>

<script>
import { createMetaMixin } from 'quasar';
import { mapState } from 'vuex';

export default {
    name: 'App',
    created() {
        const loadUser = () => {
            this.$e.user.load((user) => {
                if (user == null) {
                    this.$router.logout();
                } else {
                    this.$router.login();
                }
            });
        };

        this.$e.storageKey = 'enfis-';
        this.$e.io.callMethod('getApplicationInfo', null, {
            success: (info) => {
                this.title = info.applicationName;
                if (info.systemTypeCode === 'D') {
                    this.system = 'development';
                } else if (info.systemTypeCode === 'T') {
                    this.system = 'test';
                } else if (info.systemTypeCode === 'P') {
                    this.system = 'product';
                }

                let infoMessage = 'Názov aplikácie: ' + info.applicationName + '\n';
                infoMessage += 'Verzia aplikácie: ' + info.applicationVersion + '\n';
                infoMessage += 'Verzia databázy: ' + info.databaseVersion + '\n';
                infoMessage += 'Dátum vydania: ' + info.releaseDate + '\n';
                infoMessage += 'Typ systému: ' + info.systemTypeName;
                this.$store.commit('setAppInfo', infoMessage);

                const storageKey = 'enfis-' + info.applicationName.toLowerCase() + '-';
                this.$e.storageKey = storageKey;
                this.$e.user.onSetUser((user) => {
                    this.$e.storageKey = storageKey + user.account.login + '-';
                });
                loadUser();
            },
            error: (error) => {
                loadUser();
                return false;
            },
        });
    },
    data() {
        return {
            title: 'Administrácia',
            system: 'development',
        };
    },
    computed: {
        ...mapState(['appTitle']),
    },
    mixins: [
        createMetaMixin(function () {
            return {
                title: this.title + ': ' + (this.appTitle || 'Administrácia'),
                bodyAttr: {
                    system: this.system,
                },
            };
        }),
    ],
};
</script>
